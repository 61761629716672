import React from 'react'

import NavBar from '../components/nav'
import Footer from '../components/footer'
import SEO from '../components/seo'
import appStore from '../images/app-store-badge.png'
import playStore from '../images/play-store-badge.png'
import smithRivasLogo from '../images/sponsors/SmithRivasLogo.png'

export default () => (
    <div>
        <SEO
            title="Get better grades and have more free time!"
            keywords={[`aspen`, `grades`, `grade corner`, `smithrivas`, `online study course`]}
        />
        <body className="leading-normal tracking-normal text-white gradient">
            <NavBar />
            {/* Hero */}
            <div className="pt-4">
                <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center pb-6">
                    <div className="flex flex-col w-full md:w-4/5 justify-center items-start text-center md:text-left">
                        <h1 className="my-4 text-5xl font-bold leading-tight w-full">
                            <i>Better Grades in Less Time</i>® the SMITH RIVAS Online Study Skills Course
                        </h1>
                        <p className="leading-normal text-2xl mb-8">
                            Get better grades and have more free time!
                        </p>
                    </div>
                </div>
            </div>

            {/* who is smith rivas */}
            <section className="bg-white py-8">
                <div className="container max-w-5xl mx-auto m-8">
                    <img className="px-3" src={smithRivasLogo} alt="Smith Rivas Logo"></img>
                    <div className="flex flex-wrap text-center">
                        <div className="w-5/6 mx-auto p-6">
                            <p className="text-2xl ml-5 text-gray-800 mb-3 list-disc">
                                Grade Corner is proud to announce a partnership with SMITH RIVAS Study Skills
                                & Academic Coaching so that Grade Corner students can get a discount on the
                                award-winning <i>Better Grades in Less Time</i>® Online Study Skills Course
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* about the course */}
            <section className="bg-gray-100 py-8">
                <div className="container mx-auto px-2 pt-4 pb-12 text-gray-800 text-center">
                    <p className="text-2xl ml-5 text-gray-800 mb-5">
                        Better Grades in Less Time® teaches you the six most important study skills in depth,
                        and culminates in you designing your own custom study skills system by following the
                        same SMITH RIVAS methods that work for students at top universities.
                    </p>
                    <div className="flex flex-row flex-wrap">
                        <div className="flex flex-col mx-auto">
                            <p className="flex text-2xl">Focus on the following:</p>
                            <ul className=" text-xl ml-10 text-gray-800 mb-3 list-disc text-left">
                                <li className="">Motiviation and Time Management</li>
                                <li className="">Reading and Notetaking</li>
                                <li className="">Tests and Papers</li>
                            </ul>
                        </div>
                        <div className="flex mt-auto mx-auto rounded-b rounded-t-none overflow-hidden  p-6">
                            <div className="flex mx-auto items-center justify-end">
                                <a href="https://www.smithrivasonlinecourses.com/">
                                    <button className="text-2xl mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                                        Learn more about the course
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* the discount */}
            <section className="bg-white py-8">
                <div className="container mx-auto flex flex-wrap pt-4 pb-12 text-center">
                    <h3 className="w-full my-2 text-3xl font-bold leading-tight text-gray-800">
                        Now Grade Corner users get a discount!
                    </h3>
                    <div className="w-full mb-4">
                        <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t"></div>
                    </div>
                    <p className="w-full text-2xl ml-5 text-gray-800 mb-5 ">
                        Ready to get better study skills? Use coupon code{' '}
                        <a
                            className="text-orange-600"
                            href="https://www.smithrivasonlinecourses.com/offers/LtNBXBUf/checkout?coupon_code=GradeCorner"
                        >
                            GRADECORNER
                        </a>{' '}
                        to get $25 off!
                    </p>
                    <div className="flex mt-auto mx-auto rounded-b rounded-t-none overflow-hidden  p-6">
                        <div className="flex mx-auto items-center justify-end">
                            <a href="https://www.smithrivasonlinecourses.com/offers/LtNBXBUf/checkout?coupon_code=GradeCorner">
                                <button className="text-2xl mx-auto lg:mx-0 hover:underline gradient text-white font-bold rounded-full my-6 py-4 px-8 shadow-lg">
                                    Click here to get started and save $25
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Waves SVG */}
            <svg className="wave-top" viewBox="0 0 1439 147" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(-1.000000, -14.000000)" fill-rule="nonzero">
                        <g className="wave" fill="#ffffff">
                            <path d="M1440,84 C1383.555,64.3 1342.555,51.3 1317,45 C1259.5,30.824 1206.707,25.526 1169,22 C1129.711,18.326 1044.426,18.475 980,22 C954.25,23.409 922.25,26.742 884,32 C845.122,37.787 818.455,42.121 804,45 C776.833,50.41 728.136,61.77 713,65 C660.023,76.309 621.544,87.729 584,94 C517.525,105.104 484.525,106.438 429,108 C379.49,106.484 342.823,104.484 319,102 C278.571,97.783 231.737,88.736 205,84 C154.629,75.076 86.296,57.743 0,32 L0,0 L1440,0 L1440,84 Z"></path>
                        </g>
                        <g transform="translate(1.000000, 15.000000)" fill="#FFFFFF">
                            <g transform="translate(719.500000, 68.500000) rotate(-180.000000) translate(-719.500000, -68.500000) ">
                                <path
                                    d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                                    opacity="0.100000001"
                                ></path>
                                <path
                                    d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                                    opacity="0.100000001"
                                ></path>
                                <path
                                    d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                                    opacity="0.200000003"
                                ></path>
                            </g>
                        </g>
                    </g>
                </g>
            </svg>

            {/* CTA block */}
            <section className="flex flex-col justify-center container mx-auto text-center py-6 mb-12">
                <h3 className="mt-4 mb-8 text-3xl leading-tight">Download Grade Corner For Free</h3>
                <div className="flex flex-row w-full">
                    <a
                        className=" ml-3 w-full"
                        href="https://itunes.apple.com/us/app/grade-corner/id1441670302?ls=1&mt=8"
                    >
                        <img className="mx-auto" src={appStore} alt="Apple Store Logo"></img>
                    </a>
                    <a
                        className=" mx-3 w-full"
                        href="https://play.google.com/store/apps/details?id=com.romandc.gradecorner&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    >
                        <img className="mx-auto" src={playStore} alt="Google Play Store Logo"></img>
                    </a>
                </div>
            </section>

            {/* Footer */}
            <Footer></Footer>
        </body>
    </div>
)
